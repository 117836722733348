import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth.module.js'
import organisationModule from "./modules/organisations.module";
import userModule from "./modules/users.module";
import alertsModule from "./modules/alerts.module";
import sitesModule from "./modules/sites.module";
import hoseConfigurationsModule from "./modules/hoseConfigurations.module";
import hosesModule from "./modules/hoses.module";
import configModule from "./modules/config.module";
import inspectionsModule from "./modules/inspections.module";
import quotesModule from "./modules/quotes.module";
import clientsModule from "@/store/modules/clients.module";
import manufacturersModule from "@/store/modules/manufacturers.module";
import batchesModule from "@/store/modules/batches.module";
import clientUsersModule from "@/store/modules/clientUsers.module";
import hoseMetaModule from "@/store/modules/hoseMeta.module";

Vue.use(Vuex);

var store = new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    auth : authModule,
    clients : clientsModule(),
    clientUsers : clientUsersModule(),
    clientsEdit : clientsModule(),
    batches: batchesModule(),
    organisations : organisationModule(),
    organisationSelect : organisationModule(),
    organisationsDashboard : organisationModule(),
    organisationsDashboard2 : organisationModule(),
    users : userModule(),
    userSelect : userModule(),
    alerts : alertsModule,
    sites : sitesModule(),
    siteSelect : sitesModule(),
    hoseConfigurations : hoseConfigurationsModule(),
    hoses : hosesModule(),
    hosesEdit : hosesModule(),
    hosesDashboard : hosesModule(),
    hoseMeta : hoseMetaModule(),
    inspections : inspectionsModule(),
    inspectionsDashboard : inspectionsModule(),
    quotes : quotesModule(),
    quotesDashboard : quotesModule(),
    manufacturers : manufacturersModule(),
    config : configModule,
  },
  plugins: []
});
export default store;
